.NewsPage {
  width: 100%;
}

.NewsPageTextTitle {
  font-size: 20px;
  margin-top: 50px;
  margin-bottom: 15px;
  //text-transform: uppercase;
  font-weight: normal;
}

.NewsPageTextDate {
  color: #afafaf;
  margin-bottom: 15px;
}

.NewsPageMainImage {
  width: 100%;
}

.NewsPageTextText {
  margin-bottom: 50px;
  line-height: 30px;
  margin-top: 50px;
}

.NewsPageGallery {
  margin-bottom: 50px;
  width: 100%;
}
