@media screen and (max-width: 1000px) {
  body {
    .RcAboutRedBoxContainer {
      padding-right: 40px;
      padding-left: 40px;
    }
  }
}

@media screen and (max-width: 400px) {
  body {
    .RcAboutRedBoxContainer {
      padding: 95px 10px 50px 10px;
    }
  }
}

.RcAboutRedBoxContainer {
  background-color: #c1001e;
  color: white;
  padding: 95px 199px 50px 65px;
  font-size: 30px;
  line-height: 58px;
  letter-spacing: 1.7px;
  width: 100%;
}

.RcAboutRedBox {
  border-left: 6px solid white;
  padding-left: 15px;
  margin: auto;
}

.RcAboutRedBoxImage {
  width: 88px;
  margin-bottom: 0px;
}
