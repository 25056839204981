@media screen and (max-width: 768px) {
  body {
    .FullImageWithTextText {
      margin-left: 0;
      max-width: none;
    }
  }
}
@media screen and (max-width: 1225px) {
  body {
    .FullImageWithTextText {
      margin-left: 0;
    }
  }
}

.FullImageWithTextWrapper {
  width: 100%;
  min-height: 100vh;
  background-color: #131224;
}

.FullImageWithTextPhoto {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-size: cover;
  z-index: 1;
}

.FullImageWithTextText {
  margin-top: calc(100vh - 205px);
  margin-left: -40px;
  padding: 40px;
  position: relative;
  z-index: 2;
  color: white;
  background-color: rgba(19, 18, 36, 0.2);
  h1 {
    text-transform: uppercase;
    font-size: 32px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 10px;
  }
  p {
    line-height: 32px;
    margin-top: 30px;
    margin-bottom: 0;
  }
  // max-width: 416px;
  max-width: 600px;
  font-size: 20px;
}
