@media screen and (max-width: 1000px) {
  body {
    .DevelopmentTextImage {
      display: none;
    }
    .DevelopmentTextBuilding {
      width: calc(50% - 7.5px);
    }

    .DevelopmentTextBuilding:nth-child(3n + 2) {
      margin-left: 0;
      margin-right: 0;
    }
    .DevelopmentTextBuilding:nth-child(2n + 2) {
      margin-left: 15px;
    }
    .DevelopmentTextLogo {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  body {
    .DevelopmentTextContainer {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
@media screen and (max-width: 500px) {
  body {
    .DevelopmentTextBuilding {
      width: calc(100%);
    }

    .DevelopmentTextBuilding:nth-child(2n + 2) {
      margin-left: 0px;
    }

    .DevelopmentTextDevelopment {
      width: 100%;
      margin-bottom: 15px;
    }

    .DevelopmentTextFilters {
      flex-wrap: wrap;
    }
  }
}

.DevelopmentTextContainer {
  width: 100%;
  margin-top: 95px;
}

.DevelopmentTextWrapper {
  margin: auto;
}

.DevelopmentTextFilterText {
  font-size: 20px;
  margin-top: 60px;
}

.DevelopmentTextFilters {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.DevelopmentTextDevelopment {
  background-color: #f2f2f2;
  width: calc(33.33% - 10px);
  padding: 15px;
  position: relative;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
}

.DevelopmentTextDevelopmentSelected {
  background-color: #131224;
  color: white;
}

.DevelopmentTextTitle {
  font-weight: bold;
  font-size: 20px;
  position: relative;
}

.DevelopmentTextImage {
  max-height: 20px;
  position: absolute;
  right: 0;
  max-width: 60px;
}

.DevelopmentTextBuildings {
  margin-top: 15px;
  display: flex;
  //justify-content: space-between;
  flex-wrap: wrap;
}

.DevelopmentTextBuilding {
  width: calc(33.33% - 10px);
  background-size: cover;
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.DevelopmentTextBuilding:nth-child(3n + 2) {
  margin-left: 15px;
  margin-right: 15px;
}

.DevelopmentTextBuilding:after {
  content: "";
  display: block;
  padding-bottom: 100%;
  //margin-bottom: 15px;
}

.DevelopmentTextBuildingSqure {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  padding: 20px;
  align-items: flex-start;
  user-select: none;
}

.DevelopmentTextBuildings {
  margin-top: 50px;
  margin-bottom: 80px;
}

.DevelopmentTextBuildingTitle {
  font-weight: bold;
  font-size: 20px;
  position: relative;
  z-index: 2;
}

.DevelopmentTextBuildingFade {
  background-image: linear-gradient(rgba(255, 255, 255, 0), white);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 200px;
  z-index: 1;
}

.DevelopmentTextBuildingRent {
  background-color: #c1001e;
  font-size: 12px;
  font-weight: bold;
  color: white;
  z-index: 2;
  position: relative;
  display: inline-flex;
  padding: 3px 5px;
  margin-bottom: 10px;
}

.DevelopmentTextLogo {
  max-width: 550px;
  max-height: 100px;
  margin-top: 60px;
}

.DevelopmentText {
  line-height: 40px;
  p {
    font-size: 20px;
    max-width: 560px;
  }
  h1 {
    margin-top: 50px;
    margin-bottom: 40px;
  }
}

.DevelopmentTextLink {
  font-weight: bold;
  font-size: 18px;
  text-decoration: underline;
  margin-top: 20px;
  cursor: pointer;
  user-select: none;
}

.DevelopmentTextMap {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 90px;
}

.DevelopmentTextGMap {
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  margin-top: 40px;
  margin-bottom: 90px;
}
