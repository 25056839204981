@media screen and (max-width: 1000px) {
  body {
    .TwoBrokerCardContainer {
      padding-right: 20px;
      padding-left: 26px;
    }
    .TwoBrokerCardWrapper {
      flex-direction: column;
    }
    .TwoBrokerCardWrapper {
      padding-left: 0!important;
      padding-right: 0!important;
    }
  }
}

@media screen and (max-width: 400px) {
  body {
    .TwoBrokerCardContainer {
      padding: 95px 10px 50px 10px;
    }
    
  }
}
@media screen and (max-width: 1200px) {
  body {
    .TwoBrokerCardWrapper {
      flex-direction: column;
    }
    .TwoBrokerCard {
      margin: 0;
    }
    .TwoBrokerCardSecond {
      margin-top: 40px;
    }
  }
}

.TwoBrokerCardTitle {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 25px;
}

.TwoBrokerCardContainer {
  background-color: #c1001e;
  color: white;
  padding: 40px 65px 50px 65px;
  font-size: 30px;
  line-height: 58px;
  letter-spacing: 1.7px;
  width: 100%;
}

.TwoBrokerCard {
  border-left: 6px solid white;
  padding-left: 15px;
  font-size: 20px;
  line-height: normal;
  display: flex;
  margin: 20px;
  p {
    margin: 0;
  }
}

.TwoBrokerCardImage {
  width: 88px;
  margin-bottom: 0px;
}

.TwoBrokerCardPhoto {
  width: 72px;
  height: 72px;
  background-size: cover;
  border-radius: 1000px;
  overflow: hidden;
  margin-right: 20px;
  flex-shrink: 0;
}

.TwoBrokerCardBroker {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.TwoBrokerCardBrokerContact {
  font-size: 20px;
  text-transform: none;
  div {
    height: 25px;
  }
}

.TwoBrokerCardWrapper {
 display: flex;
 flex-direction: row;
}

.TwoBrokerCardContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.mobileMenuButtonClickContainer {
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}