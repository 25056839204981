@media screen and (max-width: 1000px) {
  body {
    .AboutPhoto {
      left: 0;
    }
    .AboutContainer {
      padding-right: 0px;
      padding-left: 0px;
    }
    .AboutPhotoLinkBuilding {
      display: none;
    }
    // .AboutPhotoDark {
    //   left: 40px;
    // }
    // .AboutPhoto {
    //   left: 40px;
    // }
    .AboutBoxDark {
      // margin-left: 20px;
      // margin-right: 20px;
    }
    .AboutTitle {
      padding-right: 40px !important;
      padding-left: 40px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  body {
    .AboutPhoto {
      width: 100%;
      margin-left: 0;
    }
    .AboutBoxDark,
    .AboutBoxLight {
      width: 100%;
      margin-left: 0;
      max-width: 100%;
    }
  }
}

.AboutContainer {
  padding-right: 64px;
  padding-left: 64px;
  margin-bottom: 96px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.AboutDark {
  background-color: #131224;
  padding-bottom: 100px;
}

.AboutTitle {
  font-size: 50px;
  text-transform: uppercase;
  margin-top: 90px;
  letter-spacing: 1.8px;
  width: 100%;
}

$AboutPhotoContainerHeight: 1224px;

.AboutPhotoContainer {
  margin-top: 70px;
  //height: $AboutPhotoContainerHeight;
  position: relative;
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.AboutPhoto {
  //position: absolute;
  // top: 0;
  // bottom: 0;
  // left: 130px;
  // right: 0;
  width: calc(100% - 130px);
  margin-left: 130px;
  background-size: auto 100%;
  background-position-x: -405px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.AboutBoxLight {
  background-color: white;
  padding: 38px;
  font-size: 18px;
  flex-direction: column;
  color: black;
  max-width: 500px;
  z-index: 10;
  position: relative;
  p {
    margin: 0px auto;
  }
  h1 {
    margin: 0px auto;
    font-weight: normal;
  }
  margin-bottom: 35px;
  margin-left: -130px;
}

.AboutBoxLight:last-child {
  margin-bottom: 0;
}

.AboutBoxDark {
  background-color: #131224;
  padding: 38px;
  font-size: 18px;
  flex-direction: column;
  color: white;
  max-width: 500px;
  z-index: 10;
  position: relative;
  p {
    margin: 0px auto;
  }
  h1 {
    margin: 0px auto;
    font-weight: normal;
  }
  margin-bottom: 35px;
  margin-left: -130px;
}

.AboutBoxDark:last-child {
  margin-bottom: 0;
}

.AboutPhotoLinkBuilding {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  max-width: 200px;
  position: absolute;
  right: 0;
  cursor: pointer;
  text-align: right;
  color: #535353;
  p {
    margin: 0;
  }
}

.AboutPhotoLinkBuildingButton {
  background-color: #131224;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 9px;
  margin-top: 20px;
  text-align: center;
}
