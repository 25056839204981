@media screen and (max-width: 1000px) {
  body {
    .OurTeamPerson {
      width: calc(50% - 25px);
    }
  }
}

@media screen and (max-width: 500px) {
  body {
    .OurTeamPerson {
      width: 100%;
      //margin-bottom: 0;
      margin-right: 0;
    }
    .OurTeamWrapper {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .OurTeamPerson {
    align-items: center;
  }
}

@media screen and (min-width: 1001px) {
  body {
    .OurTeamPerson:nth-child(3n + 3) {
      margin-right: 0px;
    }
  }
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  body {
    .OurTeamPerson:nth-child(2n + 2) {
      margin-right: 0px;
    }
  }
}

.OurTeamWrapper {
  width: 100%;
  margin-top: 90px;
}

.OurTeamText {
  max-width: 560px;
  margin-top: 30px;
  line-height: 28px;
}

.OurTeamPersons {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
}

.OurTeamPerson {
  width: calc(33.33% - 33.33px);
  margin-bottom: 20px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
}

.OurTeamPersonWrapper {
  width: 75%;
  align-items: center;
  display: flex;
  flex-direction: column;
  div {
    text-align: center;
  }
}

.OurTeamPhoto {
  width: 100%;
  background-size: cover;
  border-radius: 1000px;
}

.OurTeamPhoto:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.OurTeamName {
  font-weight: bold;
  margin-top: 10px;
}

.OurTeamJob {
  font-size: 15px;
}
