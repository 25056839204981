@media screen and (max-width: 768px) {
  body {
    .SmallImageTitleText {
      margin-left: 0;
      max-width: none;
    }
    .NewsBigTitle {
      margin-top: 150px;
    }
  }
}

@media screen and (min-width: 1280px) {
  body {
    .SmallImageTitlePhoto,
    .SmallImageTitleWrapper {
      height: 500px;
    }
  }
}

.SmallImageTitleWrapper {
  width: 100%;
  height: 304px;
}

.SmallImageTitlePhoto {
  background-position-y: top;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 304px;
  background-size: cover;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 50px;
  padding-left: 100px;
}
.SmallImageTitleFade {
  @extend .SmallImageTitlePhoto;
  opacity: 0.4;
  background-image: linear-gradient(to bottom, transparent, black);
}
.SmallImageTitleTitle {
  font-size: 32px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
}

.nav {
  user-select: none;
}
