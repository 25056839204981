@import "./fonts.scss";
@import "./nav.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

@media screen and (max-width: 768px) {
  body {
    .blackTitle {
      font-size: 30px;
    }
    tr {
      td:nth-child(2),
      td:nth-child(3) {
        display: none;
      }
    }
    .content {
      margin-top: 70px;
    }
    .ModalBuildingTitle {
      flex-direction: column;
    }
    .ModalBuildingTitleRight {
      align-items: center;
      max-width: none;
    }
  }
}

@media screen and (max-width: 1000px) {
  body {
    .maxWidth {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

html {
  //box-sizing: border-box;
  font-family: "Barlow";
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.bold {
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}

.content {
  //margin-left: $navWidth;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}

.fullAndCenter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.redTitle {
  color: #c1001e;
  text-transform: uppercase;
  font-size: 55px;
}

.blackTitle {
  color: black;
  text-transform: uppercase;
  font-size: 55px;
}

.maxWidth {
  max-width: 848px;
  margin-left: auto;
  margin-right: auto;
}

.modalClose {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

.ModalBuildingRentGallery {
  margin-bottom: 100px;
}

.ModalGalleryWrapper {
  width: 100%;
  height: 500px;
}

.image-gallery-thumbnail-image {
  height: 60px;
  object-fit: cover;
}

.image-gallery-image {
  height: 500px;
  width: 100%;
  object-fit: cover !important;
  max-height: none !important;
}

.image-gallery-icon {
  color: #c1001e;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  width: 64px;
  height: 64px;
  background-color: rgba(255, 255, 255, 0.6);
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  border: 3px solid #c1001e;
}

.image-gallery-icon:hover {
  color: black;
}

.ModalBuildingTitle {
  display: flex;
  justify-content: space-between;
}

.ModalBuildingTitleRight {
  max-width: 300px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.ModalBuildingImage {
  max-width: 300px;
  max-height: 80px;
  object-fit: contain;
  margin-bottom: 20px;
}

.Modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  padding-top: 0px;
  line-height: 30px;
  ul,
  p {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  a {
    color: #c1001e;
    text-decoration: underline;
  }

  .ModalBuildingTitleLeft {
    h2 {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 32px;
      margin-top: 0;
    }
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
    div {
      margin-bottom: 20px;
    }
  }
}

.ModalButton {
  background-color: #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  padding: 5px 25px;
  text-decoration: none !important;
  text-transform: uppercase;
  cursor: pointer;
}

.ModalButtonIcon {
  margin-right: 10px;
}

.ModalBuildingMorerent {
  margin-top: 50px;
}

.ModalNoScroll {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  overflow: scroll;
}

.ImageGalleryNav {
  position: absolute;
  z-index: 10000;
  padding: 0px 10px 0 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #c1001e;

  width: 71px;
  /* height: 40px; */
  font-size: 30px;
  cursor: pointer;
  user-select: none;
}

.ImageGalleryNavLeft {
  left: 10px;
}
.ImageGalleryNavRight {
  right: 30px;
}

.ImageGalleryNavSquare {
  width: 71px;
  height: 71px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 500ms ease-in-out;
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  transform: translateX(1000px);
  transition: all 500ms ease-in-out;
  inset: 0px !important;
  border: none !important;
  background-color: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 0 !important;
  outline: none;
  padding: 20px;
  max-width: 848px;
  margin: 0 0 0 auto !important;
  padding: 0 !important;
}

.ReactModal__Content--after-open {
  transform: translateX(0px);
}

.ReactModal__Content--before-close {
  transform: translateX(1000px);
}

.ReactModalPortal {
  h1 {
    margin-top: 0;
    margin-bottom: 50px;
  }
  th {
    display: none;
  }
}

.modalContentPadding {
  padding: 20px;
  padding-top: 40px;
}
