@media screen and (max-width: 1000px) {
  body {
    .NewsBigItemWrapper {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      margin-bottom: 50px;
      .NewsBigItemImage {
        width: 100%;
        height: 355px;
      }
    }

    .NewsBigContactPersonBroker {
      flex-direction: column;
    }

    .NewsBigContactPersonBrokerContact {
      text-align: center;
    }
  }
}

@media screen and (max-width: 1200px) {
  body {
    .NewsBigContactPersonContainer {
      padding-right: 20px;
      padding-left: 26px;
    }
    .NewsBigContactPersonWrapper {
      flex-direction: column;
    }
    .NewsBigContactPersonWrapper {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .NewsBigContactPersonContainerWrapper {
      flex-direction: column;
    }
    .NewsBigContactPerson {
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 470px) {
  body {
    .NewsBigContactPersonContainer {
      padding: 10px;
    }
    .NewsBigContactPerson {
      border-left: none;
      padding-left: 0;
    }
    .NewsBigContactPersonContainer {
      .NewsBigContactPersonContainerWrapper {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  body {
    .NewsBigContactPersonWrapper {
      flex-direction: column;
    }
    .NewsBigContactPersonSecond {
      margin-top: 40px;
    }
  }
}

.NewsBigContainer {
  width: 100%;
}

.NewsBigTitle {
  font-weight: bold;
  font-size: 32px;
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 50px;
}

.NewsBigSelectCategories {
  display: flex;
  div:first-child {
    margin-left: 0px;
  }
}

.NewsBigCategory {
  cursor: pointer;
  padding: 8px;
  background-color: #f2f2f2;
  font-weight: bold;
  margin: 10px;
}

.NewsBigCategory:first-child {
  margin-left: 0px;
}

.NewsBigSelectedCategory {
  background-color: black;
  color: white;
}

.NewsBigLoadMoreButtonWrapper {
  display: flex;
  justify-content: center;
}

.NewsBigLoadMoreButton {
  background-color: #131224;
  color: white;
  text-transform: uppercase;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  margin-bottom: 50px;
  div:first-child {
    margin-right: 20px;
  }
}

.NewsBigItemWrapper:first-child {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
  .NewsBigItemImage {
    width: 100%;
    height: 355px;
  }
}

.NewsBigItemWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 50px;
  color: black;
}

.NewsBigItemImage {
  width: 344px;
  min-height: 247px;
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
}

.NewsBigItemContent {
  padding: 30px;
  flex-grow: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.NewsBigItemTitle {
  font-size: 20px;
}

.NewsBigItemDate {
  font-size: 16px;
  color: #afafaf;
  margin-top: 15px;
  margin-bottom: 15px;
}

.NewsBigContactPersonTitle {
  text-transform: uppercase;
  font-size: 25px;
  margin-bottom: 20px;
}

.NewsBigContactPersonContainer {
  background-color: #c1001e;
  color: white;
  padding: 25px;
  font-size: 30px;
  letter-spacing: 1.7px;
  width: 100%;
  margin-bottom: 50px;
}

.NewsBigContactPerson {
  border-left: 6px solid white;
  padding-left: 15px;
  font-size: 20px;
  line-height: normal;
  display: flex;
  p {
    margin: 0;
  }
}

.NewsBigContactPersonImage {
  width: 88px;
  margin-bottom: 0px;
}

.NewsBigContactPersonPhoto {
  width: 72px;
  height: 72px;
  background-size: cover;
  border-radius: 1000px;
  overflow: hidden;
  margin-right: 20px;
  flex-shrink: 0;
}

.NewsBigContactPersonBroker {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.NewsBigContactPersonBrokerContact {
  font-size: 20px;
  text-transform: none;
  div {
    height: 25px;
  }
}

.NewsBigContactPersonWrapper {
  display: flex;
  flex-direction: row;
}

.NewsBigContactPersonContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.NewsBigContactPersonContainerWrapper {
  flex-wrap: wrap;
  display: flex;
}

.mobileMenuButtonClickContainer {
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.NewsBigContactPersonBrokerEmail {
  text-decoration: underline;
  cursor: pointer;
}
