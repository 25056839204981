@media screen and (max-width: 1000px) {
  body {
    .TextAndImage {
      flex-direction: column;
      width: 100%;
    }
    .TextAndImageTextBox {
      width: 100%;
    }
    .TextAndImageImageBox {
      width: 100%;
    }
  }
}
.TextAndImage {
  width: 100%;
  display: flex;
}

.TextAndImageTextBox {
  width: 50%;
  margin-top: 80px;
}

.TextAndImageImageBox {
  width: 50%;
  margin-top: 80px;
  margin-bottom: 80px;
  img {
    width: 100%;
  }
}

.TextAndImageTitle {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.TextAndImageText {
  line-height: 30px;
  padding-right: 30px;
}
