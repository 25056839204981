@media screen and (max-width: 1000px) {
  body {
    .BuildingCategoriesPhoto,
    .BuildingCategories,
    .BuildingCategoriesSubBuildingImage,
    .BuildingCategoriesSubBuildingInfoWrapper {
      width: 100%;
      max-width: 100%;
    }
    .BuildingCategoriesContainer,
    .BuildingSubcategoriesContainer,
    .BuildingCategoriesSubBuildingInfoWrapper {
      padding-left: 20px;
      padding-right: 20px;
    }
    .BuildingCategoriesSubWrapper {
      flex-direction: column;
    }
  }
}

.BuildingCategoriesTitle {
  font-size: 50px;
  margin-bottom: 25px;
  letter-spacing: 1.8px;
  margin-bottom: 50px;
}

.BuildingCategoriesContainer {
  background-color: #131224;
  color: white;
  padding: 40px 65px 50px 65px;
  font-size: 30px;
  line-height: 58px;
  letter-spacing: 1.7px;
  width: 100%;
}

.BuildingCategoriesWrapper {
  position: relative;
  min-height: 295px;
}

.BuildingCategories {
  background-color: rgba(255, 255, 255, 0.7);
  font-size: 20px;
  color: black;
  line-height: normal;
  padding: 25px;
  max-width: 488px;
  position: relative;
  z-index: 2;
  p {
    margin: 0;
  }
}

.BuildingCategoriesPhoto {
  width: 488px;
  height: 295px;
  top: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  background-size: cover;
}

.BuildingSubcategoriesContainer {
  padding: 40px 65px 50px 65px;
  width: 100%;
}

.BuildingCategoriesSubTitle {
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 50px;
}

.BuildingCategoriesSubWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
}

.BuildingCategoriesSubBuildingInfoWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 50px;
  div {
    margin-bottom: 20px;
  }
}

.BuildingCategoriesSubBuildingTitle {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
}

.BuildingCategoriesSubBuildingImage {
  width: 350px;
  height: 261px;
  background-size: cover;
}

.BuildingCategoriesSubBuildingShowMoreText {
  background-color: #131224;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  padding: 6px;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  user-select: none;
}
