@media screen and (max-width: 1000px) {
  body {
    .BrokerCardContainer {
      padding-right: 20px;
      padding-left: 26px;
    }
  }
}

@media screen and (max-width: 400px) {
  body {
    .BrokerCardContainer {
      padding: 95px 10px 50px 10px;
    }
  }
}

.BrokerCardTitle {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 25px;
}

.BrokerCardContainer {
  background-color: #c1001e;
  color: white;
  padding: 40px 65px 50px 65px;
  font-size: 30px;
  line-height: 58px;
  letter-spacing: 1.7px;
  width: 100%;
}

.BrokerCard {
  border-left: 6px solid white;
  padding-left: 15px;
  margin: auto;
  text-transform: uppercase;
  font-size: 20px;
  line-height: normal;
  p {
    margin: 0;
  }
}

.BrokerCardImage {
  width: 88px;
  margin-bottom: 0px;
}

.BrokerCardPhoto {
  width: 72px;
  height: 72px;
  background-size: cover;
  border-radius: 1000px;
  overflow: hidden;
  margin-right: 20px;
  flex-shrink: 0;
}

.BrokerCardBroker {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.BrokerCardBrokerContact {
  font-size: 20px;
  text-transform: none;
  div {
    height: 25px;
  }
}
