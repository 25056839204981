@media screen and (max-width: 900px) {
  body {
    .ContactsCountriesItemLight {
      width: 100%;
      margin-bottom: 20px;
      .ContactsCountriesItemContact {
        width: 100%;
        height: 100%;
      }
    }
    .ContactsCountriesBigLight {
      padding: 100px 20px;
    }
    .ContactsCountriesLight {
      padding: 100px 20px;
    }
    .ContactsCountriesItemLight:nth-child(2n + 2) {
      margin-left: 0px;
    }
  }
}

@media screen and (max-width: 1100px) {
  body {
    .ContactsCountriesItemContactCountry {
      display: block;
    }
  }
}

@media screen and (max-width: 1000px) {
  body {
    .ContactsCountriesItemContact {
      width: 100%;
      margin-right: 0;
    }

    .ContactsCountriesItemImage {
      display: none;
    }

    .ContactsCountries {
      padding: 100px 20px;
    }
  }
}

.ContactsCountries {
  background-color: #131224;
  color: white;
  padding: 100px 60px;
  width: 100%;
}

.ContactsCountriesBigLight {
  background-color: white;
  color: white;
  padding: 100px 60px;
  width: 100%;
}

.ContactsCountriesLight {
  background-color: #e7e7e7;
  color: black;
  padding: 100px 60px;
  width: 100%;
}

.ContactsCountriesTitle {
  font-size: 50px;
  text-transform: uppercase;
  margin-bottom: 60px;
}

.ContactsCountriesItem {
  display: flex;
  margin-bottom: 40px;
}

.ContactsCountriesItemLight {
  width: calc(50% - 10px);
  margin-bottom: 20px;
  .ContactsCountriesItemContact {
    width: 100%;
    height: 100%;
  }
}

.ContactsCountriesItemLight:nth-child(2n + 2) {
  margin-left: 20px;
}

.ContactsCountriesItemContact {
  background-color: #fafafa;
  color: black;
  width: 50%;
  margin-right: 10px;
  padding: 30px;
  div {
    margin-bottom: 25px;
  }
}

.ContactsCountriesItemImage {
  width: 50%;
  background-color: #fafafa;
  flex: 1 1 0px;
  margin-left: 10px;
  background-size: cover;
}

.ContactsCountriesItemContactCountry {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ContactsCountriesItemContactHo {
  color: #c1001e;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}

.ContactsCountriesItemContactEmail {
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
}

.ContactsCountriesItemsContainerLight {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
}

.ContactsCountriesItemsContainer,
.ContactsCountriesHeader {
  margin: auto;
}
