// @media screen and (max-width: 768px) {
//   body {
//     .mobileMenuButton {
//       display: flex;
//     }
//     .nav {
//       display: none;
//     }
//   }
// }

// @media screen and (min-width: 769px) {
//   body {
//     .mobileMenuButton {
//       display: none;
//     }
//     .nav {
//       display: flex;
//     }
//   }
// }

.mobileMenuButton {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
  text-transform: uppercase;
  font-size: 20px;
  position: fixed;
  left: 0px;
  top: 0px;
  cursor: pointer;
  user-select: none;
  z-index: 1000;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  span {
    margin-right: 10px;
    display: inline-block;
  }
}

.mobileMenuButtonContainer {
  display: flex;
}

.mobileMenuButtonIconWrapper {
  padding-top: 0px;
  margin-left: 10px;
}

.mobileMenuButtonIcon {
  font-size: 24px;
}

.navMobileMenuLogo {
  width: 50px;
}

.nav {
  //width: $navWidth;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  background: white;
  overflow-x: hidden;
  padding: 27px 32px;
  text-transform: uppercase;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
}

.navItemsContainer {
  flex-grow: 1;
  min-height: 0px;
  overflow-y: scroll;
  padding-bottom: 80px;
}

.navItemsContainer::-webkit-scrollbar {
  display: none;
}

.navBoldItems {
  margin-top: 92px;
}

.navItems,
.navLangs {
  margin-top: 46px;
}

.navLogo {
  height: 90px;
}

.navItem {
  text-decoration: none;
  margin-bottom: 24px;
  display: block;
  color: black;
  cursor: pointer;
  user-select: none;
}

.navBoldItem {
  @extend .navItem;
  font-weight: bold;
  margin-bottom: 32px;
}

.navLangItem {
  @extend .navItem;
  font-size: 14px;
}

.navBoldItem:last-child {
  margin-bottom: 0px;
}

.navItem:last-child {
  margin-bottom: 0px;
}

.navLangImg {
  height: 13px;
  margin-left: 10px;
}

.navSocial {
  //position: absolute;
  //bottom: 29px;
  display: flex;
  margin-top: 80px;
}

.navSocialElement {
  width: 34px;
  height: 34px;
  background-color: #afafaf;
  margin-right: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.navSocialIconF {
  font-size: 20px;
}

.navSocialIconIg {
  font-size: 25px;
}

.navClose {
  width: 70px;
  height: 70px;
  position: fixed;
  top: 32px;
  right: 32px;
  font-size: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}
