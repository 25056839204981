@media screen and (max-width: 1000px) {
  body {
    .OurValuesContentTextEven,
    .OurValuesContentTextOdd {
      width: 100%;
      text-align: left;
      padding-left: 65px;
    }
    .OurValuesCenterLine {
      left: 16.5px;
    }
    .OurValuesCircle {
      left: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  body {
    .OurValuesWrapper {
      padding: 20px;
    }
  }
}

.OurValuesContainer {
  width: 100%;
  margin-top: 125px;
  position: relative;
}

.OurValuesWrapper {
  width: 100%;
  margin: auto;
  position: relative;
  z-index: 2;
}

.OurValuesContent {
  margin-top: 60px;
  position: relative;
}

.OurValuesBg {
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-size: cover;
  background-position: center;
}

.OurValuesContentTextEven,
.OurValuesContentTextOdd {
  h1 {
    font-weight: normal;
    margin-bottom: 0;
  }
  p {
    margin-top: 0;
    line-height: 30px;
  }
  padding: 0 40px;
  margin-bottom: 40px;
}

.OurValuesContentTextEven {
  width: 50%;
  text-align: right;
}

.OurValuesContentTextOdd {
  width: 50%;
  margin-left: auto;
}

.OurValuesCenterLine {
  width: 3px;
  top: 0;
  bottom: 0;
  left: calc(50% - 1.5px);
  z-index: 4;
  position: absolute;
  background-color: #131224;
}

.OurValuesCircle {
  position: absolute;
  background-color: #131224;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  overflow: hidden;
  left: calc(50% - 18px);
}
