@media screen and (max-width: 1200px) {
  body {
    .SelectCountryContainer {
      padding-left: 20px;
      padding-right: 20px;
      .SelectCountryItems {
        flex-direction: column;
      }
      .SelectCountryItem {
        text-align: center;
        margin-left: 0px;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  body {
  }
}

.SelectCountryContainer {
  width: 100%;
  margin-bottom: 60px;
  h2 {
    font-weight: normal;
  }
  .SelectCountryItems {
    display: flex;
  }

  .SelectCountryItem {
    border: 2px solid transparent;
    color: #c1001e;
    font-size: 20px;
    padding: 10px;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: underline;
    user-select: none;
  }

  .SelectCountryItemSelected {
    border: 2px solid #c1001e;
    text-decoration: none;
  }
}
