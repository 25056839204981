@media screen and (max-width: 1200px) {
  body {
    .AutomaticBrokersCardContainer {
      padding-right: 20px;
      padding-left: 26px;
    }
    .AutomaticBrokersCardWrapper {
      flex-direction: column;
    }
    .AutomaticBrokersCardWrapper {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .AutomaticBrokersCardContainerWrapper {
      flex-direction: column;
    }
    .AutomaticBrokersCard {
      margin-top: 20px;
      width: auto;
    }
  }
}

@media screen and (max-width: 470px) {
  body {
    .AutomaticBrokersCardContainer {
      padding: 95px 10px 50px 10px;
    }
    .AutomaticBrokersCardBroker {
      flex-direction: column;
    }
    .AutomaticBrokersCardBrokerContact {
      width: 100%;
      text-align: center;
    }
  }
}
@media screen and (max-width: 1200px) {
  body {
    .AutomaticBrokersCardWrapper {
      flex-direction: column;
    }
    .AutomaticBrokersCardSecond {
      margin-top: 40px;
    }
  }
}

.AutomaticBrokersCardTitle {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 25px;
}

.AutomaticBrokersCardContainer {
  background-color: #c1001e;
  color: white;
  padding: 40px 65px 50px 65px;
  font-size: 30px;
  line-height: 58px;
  letter-spacing: 1.7px;
  width: 100%;
}

.AutomaticBrokersCard {
  border-left: 6px solid white;
  padding-left: 15px;
  font-size: 20px;
  line-height: normal;
  display: flex;
  margin: 20px;
  width: 45%;
  p {
    margin: 0;
  }
}

.AutomaticBrokersCardImage {
  width: 88px;
  margin-bottom: 0px;
}

.AutomaticBrokersCardPhoto {
  width: 72px;
  height: 72px;
  background-size: cover;
  border-radius: 1000px;
  overflow: hidden;
  margin-right: 20px;
  flex-shrink: 0;
}

.AutomaticBrokersCardBroker {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.AutomaticBrokersCardBrokerContact {
  font-size: 20px;
  text-transform: none;
  div {
    height: 25px;
  }
}

.AutomaticBrokersCardWrapper {
  display: flex;
  flex-direction: row;
}

.AutomaticBrokersCardContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.AutomaticBrokersCardContainerWrapper {
  flex-wrap: wrap;
  display: flex;
}

.mobileMenuButtonClickContainer {
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
