@media screen and (max-width: 1000px) {
  body {
    .PortfolioImage {
      display: none;
    }
    .PortfolioBuilding {
      width: calc(50% - 7.5px);
    }

    .PortfolioBuilding:nth-child(3n + 2) {
      margin-left: 0;
      margin-right: 0;
    }
    .PortfolioBuilding:nth-child(2n + 2) {
      margin-left: 15px;
    }
  }
}

@media screen and (max-width: 768px) {
  body {
    .PortfolioContainer {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
@media screen and (max-width: 500px) {
  body {
    .PortfolioBuilding {
      width: calc(100%);
    }

    .PortfolioBuilding:nth-child(2n + 2) {
      margin-left: 0px;
    }

    .PortfolioDevelopment {
      width: 100%;
      margin-bottom: 15px;
    }

    .PortfolioFilters {
      flex-wrap: wrap;
    }
  }
}

.PortfolioContainer {
  width: 100%;
  margin-top: 95px;
}

.PortfolioWrapper {
  margin: auto;
}

.PortfolioFilterText {
  font-size: 20px;
  margin-top: 60px;
}

.PortfolioFilters {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.PortfolioDevelopment {
  background-color: #f2f2f2;
  width: calc(33.33% - 10px);
  padding: 15px;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  user-select: none;
  margin-bottom: 15px;
}

.PortfolioDevelopmentSelected {
  background-color: #131224;
  color: white;
}

.PortfolioTitle {
  font-weight: bold;
  font-size: 20px;
  position: relative;
}

.PortfolioImage {
  max-height: 20px;
  position: absolute;
  right: 0;
  max-width: 60px;
  display: none;
}

.PortfolioBuildings {
  margin-top: 15px;
  display: flex;
  //justify-content: space-between;
  flex-wrap: wrap;
}

.PortfolioBuilding {
  width: calc(33.33% - 10px);
  background-size: cover;
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;
  user-select: none;
  /* filter: url(filters.svg#grayscale); Firefox 3.5+ */
  filter: gray; /* IE5+ */
  -webkit-filter: grayscale(0); /* Webkit Nightlies & Chrome Canary */
  -webkit-transition: all 0.2s ease-in-out;
  transition: transform 0.2s;
}

.PortfolioBuilding:hover {
  transform: scale(1.05);
}

.PortfolioBuilding:nth-child(3n + 2) {
  margin-left: 15px;
  margin-right: 15px;
}

.PortfolioBuilding:after {
  content: "";
  display: block;
  padding-bottom: 100%;
  //margin-bottom: 15px;
}

.PortfolioBuildingSqure {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  padding: 20px;
  align-items: flex-start;
  user-select: none;
}

.PortfolioBuildings {
  margin-top: 50px;
  margin-bottom: 80px;
}

.PortfolioBuildingTitle {
  font-weight: bold;
  font-size: 20px;
  position: relative;
  z-index: 2;
}

.PortfolioBuildingFade {
  background-image: linear-gradient(rgba(255, 255, 255, 0), white);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 200px;
  z-index: 1;
}

.PortfolioBuildingRent {
  background-color: #c1001e;
  font-size: 12px;
  font-weight: bold;
  color: white;
  z-index: 2;
  position: relative;
  display: inline-flex;
  padding: 3px 5px;
  margin-bottom: 10px;
}
