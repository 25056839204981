@media screen and (max-width: 1000px) {
  body {
    .NewsModule {
      padding-left: 40px;
      padding-right: 40px;
    }
    .NewsModuleHeader {
      display: block;
    }
    .NewsModuleLink {
      display: inline-flex;
      margin-top: 25px;
    }
    .NewsModuleItem {
      width: auto;
    }
  }
}

@media screen and (max-width: 599px) {
  body {
    .NewsModuleItemsContainer {
      flex-direction: column;
    }
    .NewsModuleItem {
      margin-bottom: 30px;
      margin-right: 0;
    }
  }
}

.NewsModule {
  padding: 120px 60px;
}

.NewsModuleHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.NewsModuleLink {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #131224;
  color: white;
  text-transform: uppercase;
  font-size: 16px;
  padding: 0 40px;
}

.NewsModuleLinkArrow {
  margin-left: 10px;
}

.NewsModuleItemsContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 45px;
}

.NewsModuleItem {
  margin-right: 16px;
  background-color: #f5f5f5;
  cursor: pointer;
  width: 33%;
  display: flex;
  flex-direction: column;
}

.NewsModuleItem:last-child {
  margin-right: 0;
}

.NewsModuleItemImage {
  height: 189px;
  background-size: cover;
  background-position: center;
}

.NewsModuleTextContainer {
  padding: 20px;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
}

.NewsModuleTextTitle {
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: auto;
}

.NewsModuleTextDate {
  color: #afafaf;
  margin-bottom: 15px;
  margin-top: 15px;
}

.NewsModuleTextText {
  margin-bottom: 10px;

  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
