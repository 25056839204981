@media screen and (max-width: 1000px) {
  body {
    .LeftTextRightImagesWrapper {
      padding: 0px;
    }
  }
}

@media screen and (max-width: 768px) {
  body {
    .LeftTextRightImagesWrapper {
      padding: 20px;
    }
  }
}

@media screen and (max-width: 600px) {
  body {
    .LeftTextRightImagesImages {
      display: none;
    }
    .LeftTextRightImagesText {
      width: 100%;
    }
  }
}

.LeftTextRightImagesWrapper {
  margin-top: 0px;
  display: flex;
  //padding: 65px;
  padding-top: 10px;
  box-sizing: border-box;
}

.LeftTextRightImagesText {
  width: 50%;
  box-sizing: border-box;
  padding-right: 20px;
  img {
    width: 48px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  h1 {
    font-weight: normal;
    letter-spacing: 0.5px;
    margin-bottom: 35px;
    font-size: 30px;
  }
  h1:first-child {
    margin-top: 0;
  }
  p {
    font-size: 18px;
    line-height: 28px;
  }
  p:last-child {
    margin-bottom: 0;
  }
}

.LeftTextRightImagesImages {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.LeftTextRightImagesImage {
  //height: 277px;
  background-size: cover;
}
