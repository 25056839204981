@media screen and (max-width: 1000px) {
  body {
    .HomeBox {
      width: 80%;
      margin-left: 10%;
    }
  }
}

@media screen and (max-width: 768px) {
  body {
    .HomeBox {
      margin-top: 150px;
    }
    .ModalBuildingImage {
      display: none;
    }
  }
}

@media screen and (max-width: 500px) {
  body {
    .HomeBox {
      width: 100%;
      margin-left: 0;
    }
  }
}

.HomeMainPhotoWrapper {
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.HomeMainPhoto {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  position: absolute;
  z-index: 2;
  transform: scale(1.3);
  //transform-origin: top left;
}

.CoverVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.HomeMainPhotoAnimation {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 1;
}

.HomeBox {
  width: 488px;
  min-height: 351px;
  background-color: rgba(255, 255, 255, 0.8);
  margin-top: 33px;
  margin-left: 64px;
  padding: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.HomeTitleText {
  font-weight: bold;
  font-size: 32px;
  text-transform: uppercase;
  line-height: 50px;
  margin-top: -10px;
  color: #131224;
}

.HomeText {
  margin-top: 37px;
  line-height: 30px;
  flex-grow: 1;
  color: #131224;
}

.HomeButton {
  width: 100%;
  height: 54px;
  background-color: white;
  text-transform: uppercase;
  align-items: center;
  display: flex;
  font-weight: 300;
  font-size: 18px;
  padding-left: 23px;
  padding-right: 23px;
  letter-spacing: 1.1px;
  box-shadow: 0px 9px 26px rgba(0, 0, 0, 0.15);
  color: black;
  position: relative;
  margin-top: 20px;
  justify-content: space-between;
}

.HomeButtonIcon {
  // position: absolute;
  // right: 23px;
}
