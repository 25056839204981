.LabelWithBox {
  width: 100%;
}

@media screen and (max-width: 1000px) {
  body {
    .LabelWithBoxText {
      width: 100%;
      max-width: 100%;
    }
  }
}

.LabelWithBoxTitle {
  margin-top: 60px;
}

.LabelWithBoxText {
  background-color: #131224;
  margin-top: 30px;
  color: white;
  max-width: 488px;
  padding: 15px 30px;
  p {
    max-width: 400px;
    font-size: 20px;
    line-height: 40px;
    margin: 0;
  }
}
