@media screen and (max-width: 1000px) {
  body {
    .InvestorSlider {
      flex-direction: column;
    }
    .InvestorSliderTextBox,
    .InvestorSliderImageBoxWrapper {
      width: 100%;
    }
    .InvestorSliderNav {
      margin-top: 20px;
    }
  }
}
.InvestorSlider {
  width: 100%;
  display: flex;

  .image-gallery-thumbnail-image {
    height: 60px;
    object-fit: cover;
  }

  .image-gallery-image {
    height: 190px;
    width: 100%;
    object-fit: contain !important;
    max-height: none !important;
  }

  .image-gallery-icon {
    color: white;
  }

  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    width: 64px;
    height: 64px;
    background-color: #131224;
    bottom: 0;
    position: absolute;
  }

  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:hover,
  .image-gallery-thumbnail:focus {
    border: none;
  }

  .image-gallery-icon:hover {
    color: black;
  }
}

.InvestorSliderTextBox {
  width: 50%;
  margin-top: 80px;
}

.InvestorSliderImageBoxWrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 80px;
  background-color: #fafafa;
  padding: 20px;
}

.InvestorSliderImageBox {
}

.InvestorSliderTitle {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.InvestorSliderText {
  line-height: 30px;
  padding-right: 30px;
}

.InvestorSliderNav {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-top: 20px;
  color: white;
  div {
    padding: 10px;
    background-color: #131224;
    cursor: pointer;
    user-select: none;
  }
}

.InvestorSliderNavYear {
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
